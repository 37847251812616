import { combineReducers } from "redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import { connectRouter } from "connected-react-router";
import Account from "./Account";

export default (history) =>
	combineReducers({
		router: connectRouter(history),
		settings: Settings,
		auth: Auth,
		common: Common,
		account: Account,
	});
