import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}sample`}
        component={asyncComponent(() => import("./SamplePage"))}
      />
      <Route
        path={`${match.url}calendar`}
        component={asyncComponent(() => import("./CalendarView"))}
      />
      <Route
        path={`${match.url}credentials`}
        component={asyncComponent(() => import("./Credentials"))}
      />
      <Route
        path={`${match.url}zoom-users`}
        component={asyncComponent(() => import("./ZoomUsers"))}
      />
      <Route
        path={`${match.url}meetings`}
        component={asyncComponent(() => import("./Meeting"))}
      />
      <Route
        path={`${match.url}users`}
        component={asyncComponent(() => import("./User"))}
      />
    </Switch>
  </div>
);

export default App;
