import {
	CLOSE_MODAL_ACCOUNT,
	OPEN_MODAL_ACCOUNT,
} from "../../constants/ActionTypes";

export const openModal = () => {
	return {
		type: OPEN_MODAL_ACCOUNT,
		payload: true,
	};
};

export const closeModal = () => {
	return {
		type: CLOSE_MODAL_ACCOUNT,
		payload: false,
	};
};
