import React, { useEffect, useState } from "react";
import { Button, Form, Input, message } from "antd";
import IntlMessages from "util/IntlMessages";
import { Link, useHistory } from "react-router-dom";
import AuthService from "../services/auth.service";
import { useSelector } from "react-redux";

const FormItem = Form.Item;

const ForgotPassword = (props) => {
	const [form] = Form.useForm();
	const [loading, setloading] = useState(false);
	const history = useHistory();
	const token = useSelector(({ auth }) => auth.token);

	useEffect(() => {
		if (token !== null) {
			props.history.push("/");
		}
	}, [token, props.history]);

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	const onFinish = (values) => {
		setloading(true);
		AuthService.ForgotPassword(values)
			.then((response) => {
				message.success(response.data.message);
				history.push("signin");
			})
			.catch((err) => {
				if (typeof err.response.data.errors == "object") {
					let fields = [];

					for (const error in err.response.data.errors) {
						fields.push({
							name: error,
							errors: [err.response.data.errors[error]],
						});
					}

					form.setFields(fields);
				}
			})
			.finally(() => {
				setloading(false);
			});
	};

	return (
		<div className="gx-login-container">
			<div className="gx-login-content">
				<div className="gx-login-header">
					<img alt="example" src={require("assets/images/logo.png")} />
				</div>
				<div className="gx-mb-4">
					<h2>¿Se te olvidó tu contraseña?</h2>
					<p>
						<IntlMessages id="app.userAuth.forgot" />
					</p>
				</div>

				<Form
					initialValues={{ remember: true }}
					name="basic"
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					className="gx-signin-form gx-form-row0"
					form={form}
				>
					<FormItem
						name="email"
						rules={[
							{ required: true, message: "Por favor ingrese su E-mail!" },
						]}
					>
						<Input
							className="gx-input-lineheight"
							type="email"
							placeholder="Correo Electrónico"
						/>
					</FormItem>

					<FormItem>
						<Button loading={loading} type="primary" htmlType="submit">
							<IntlMessages id="app.userAuth.send" />
						</Button>
					</FormItem>
					<Link to="/signin">
						<span>Iniciar sesión</span>
					</Link>
				</Form>
			</div>
		</div>
	);
};

export default ForgotPassword;
