import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
// import AppsNavigation from "./AppsNavigation";
import {
	NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
	NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
	THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
	let { navStyle, themeType } = useSelector(({ settings }) => settings);
	let { pathname } = useSelector(({ common }) => common);
	let { authUser } = useSelector((state) => state.auth);

	const getNoHeaderClass = (navStyle) => {
		if (
			navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
			navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
		) {
			return "gx-no-header-notifications";
		}
		return "";
	};

	const selectedKeys = pathname.substr(1);
	const defaultOpenKeys = selectedKeys.split("/")[1];
	return (
		<>
			<SidebarLogo
				sidebarCollapsed={sidebarCollapsed}
				setSidebarCollapsed={setSidebarCollapsed}
			/>
			<div className="gx-sidebar-content">
				<div
					className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
				>
					<UserProfile />
					{/* <AppsNavigation /> */}
				</div>
				<CustomScrollbars className="gx-layout-sider-scrollbar">
					<Menu
						defaultOpenKeys={[defaultOpenKeys]}
						selectedKeys={[selectedKeys]}
						theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
						mode="inline"
					>
						<Menu.Item key="calendar">
							<Link to="/calendar">
								<i className="icon icon-calendar" />
								<span>
									<IntlMessages id="sidebar.calendar" />
								</span>
							</Link>
						</Menu.Item>

						<Menu.Item key="meetings">
							<Link to="/meetings">
								<i className="icon icon-phone" />
								<span>Meetings</span>
							</Link>
						</Menu.Item>

						{authUser &&
							authUser.roles &&
							authUser.roles[0].name === "administrator" && (
								<>
									<Menu.Item key="credentials">
										<Link to="/credentials">
											<i className="icon icon-icon" />
											<span>
												<IntlMessages id="credentials.title" />
											</span>
										</Link>
									</Menu.Item>

									<Menu.Item key="zoom-users">
										<Link to="/zoom-users">
											<i className="icon icon-user" />
											<span>Usuarios Zoom</span>
										</Link>
									</Menu.Item>

									<Menu.Item key="users">
										<Link to="/users">
											<i className="icon icon-avatar" />
											<span>Usuarios</span>
										</Link>
									</Menu.Item>
								</>
							)}
					</Menu>
				</CustomScrollbars>
			</div>
		</>
	);
};

SidebarContent.propTypes = {};
export default SidebarContent;
