import React, { useState } from "react";
import { Col, Row, Form, Modal, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../appRedux/actions/Account";
import { authWithModal } from "../../util/helpers";
import AuthService from "../../services/auth.service";

const Account = () => {
	const [form] = Form.useForm();
	const isModalVisible = useSelector(
		(state) => state.account.open_modal_account
	);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const { width } = useSelector(({ common }) => common);

	const handleSubmit = (values) => {
		setLoading(true);

		AuthService.ChangePassword(values)
			.then((response) => {
				message.success("Cambio de contraseña exitoso");
				dispatch(closeModal());
			})
			.catch((err) => {
				if (typeof err.response.data.errors == "object") {
					let fields = [];

					for (const error in err.response.data.errors) {
						fields.push({
							name: error,
							errors: [err.response.data.errors[error]],
						});
					}

					form.setFields(fields);
				}

				if (typeof err.response.data.message == "string") {
					message.error(err.response.data.message);
				}
			})
			.finally(() => setLoading(false));
	};

	return (
		<Modal
			destroyOnClose={true}
			title="Cuenta"
			visible={isModalVisible}
			onOk={form.submit}
			onCancel={() => {
				dispatch(closeModal());
			}}
			confirmLoading={loading}
			width={authWithModal(width)}
		>
			<h2>Cambiar Contraseña</h2>
			<Form
				form={form}
				preserve={false}
				initialValues={{}}
				onFinish={handleSubmit}
			>
				<Row>
					<Col xs={24} md={12}>
						<Form.Item
							label="Contraseña Actual"
							name="password"
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
							rules={[
								{
									type: "string",
									message: "The input is not valid String!",
								},
								{
									required: true,
									message: "Please field is required!",
								},
							]}
						>
							<Input.Password />
						</Form.Item>
					</Col>
					<Col xs={24} md={12}>
						<Form.Item
							label="Nueva Contraseña"
							name="new_password"
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
							rules={[
								{
									type: "string",
									message: "The input is not valid String!",
								},
								{
									required: true,
									message: "Please field is required!",
								},
							]}
						>
							<Input.Password />
						</Form.Item>
					</Col>
					<Col xs={24} md={12}>
						<Form.Item
							label="Repetir Contraseña"
							name="new_password_confirmation"
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
							rules={[
								{
									type: "string",
									message: "The input is not valid String!",
								},
								{
									required: true,
									message: "Please field is required!",
								},
							]}
						>
							<Input.Password />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

export default Account;
