import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { openModal } from "../../appRedux/actions/Account";

const UserInfo = () => {
	const dispatch = useDispatch();

	const [visible, setVisible] = useState(false);

	const handleClick = (action) => {
		dispatch(action());
		setVisible(false);
	};

	const userMenuOptions = (
		<ul className="gx-user-popover">
			<li onClick={() => handleClick(openModal)}>Cuenta</li>
			<li onClick={() => handleClick(userSignOut)}>Salir</li>
		</ul>
	);

	return (
		<Popover
			overlayClassName="gx-popover-horizantal"
			placement="bottomRight"
			content={userMenuOptions}
			trigger={["click"]}
			visible={visible}
			onVisibleChange={setVisible}
		>
			<Avatar
				src={"https://via.placeholder.com/150x150"}
				className="gx-avatar gx-pointer"
				alt=""
			/>
		</Popover>
	);
};

export default UserInfo;
