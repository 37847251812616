const languageData = [
  {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us",
  },
  {
    languageId: "spanish",
    locale: "es",
    name: "Spanish",
    icon: "es",
  },
];
export default languageData;
