import {
	CLOSE_MODAL_ACCOUNT,
	OPEN_MODAL_ACCOUNT,
} from "../../constants/ActionTypes";

const INIT_STATE = {
	open_modal_account: false,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case OPEN_MODAL_ACCOUNT:
			return {
				...state,
				open_modal_account: action.payload,
			};
		case CLOSE_MODAL_ACCOUNT:
			return {
				...state,
				open_modal_account: action.payload,
			};

		default:
			return state;
	}
};
