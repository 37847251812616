import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "../../appRedux/actions/Auth";
import { openModal } from "../../appRedux/actions/Account";

const UserProfile = () => {
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const { authUser } = useSelector((state) => state.auth);

	const handleClick = (action) => {
		dispatch(action());
		setVisible(false);
	};

	const userMenuOptions = (
		<ul className="gx-user-popover">
			<li onClick={() => handleClick(openModal)}>Cuenta</li>
			<li onClick={() => handleClick(userSignOut)}>Salir</li>
		</ul>
	);

	return (
		<div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
			<Popover
				placement="bottomRight"
				content={userMenuOptions}
				trigger="click"
				visible={visible}
				onVisibleChange={setVisible}
			>
				<Avatar
					src="https://via.placeholder.com/150x150"
					className="gx-size-40 gx-pointer gx-mr-3"
					alt=""
				/>
				<span className="gx-avatar-name">
					{authUser && authUser.name}
					<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
				</span>
			</Popover>
		</div>
	);
};

export default UserProfile;
