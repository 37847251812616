import {
	FETCH_ERROR,
	FETCH_START,
	FETCH_SUCCESS,
	INIT_URL,
	SIGNOUT_USER_SUCCESS,
	USER_DATA,
	USER_TOKEN_SET,
} from "../../constants/ActionTypes";
import axios from "util/Api";

export const setInitUrl = (url) => {
	return {
		type: INIT_URL,
		payload: url,
	};
};

export const userSignUp = ({ email, password, name }) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.post("/register", {
				email: email,
				password: password,
				name: name,
			})
			.then(({ data }) => {
				console.log("data:", data);
				if (data.result) {
					localStorage.setItem(
						"token",
						JSON.stringify(data.token.access_token)
					);
					axios.defaults.headers.common["authorization"] =
						"Bearer " + data.token.access_token;
					dispatch({ type: FETCH_SUCCESS });
					dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
					dispatch({ type: USER_DATA, payload: data.user });
				} else {
					console.log("payload: data.error", data.error);
					dispatch({ type: FETCH_ERROR, payload: "Network Error" });
				}
			})
			.catch(function (error) {
				dispatch({ type: FETCH_ERROR, payload: error.message });
				console.log("Error****:", error.message);
			});
	};
};

export const userSignIn = ({ email, password }) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.post("/login", {
				email: email,
				password: password,
			})
			.then((response) => {
				const { data } = response;

				if (response.status === 200) {
					localStorage.setItem("token", data.access_token);
					axios.defaults.headers.common["Authorization"] =
						"Bearer " + data.access_token;
					dispatch({ type: FETCH_SUCCESS });
					dispatch({ type: USER_TOKEN_SET, payload: data.access_token });
					dispatch(getUser());
					dispatch(setInitUrl("calendar"));
				} else {
					dispatch({ type: FETCH_ERROR, payload: data.error });
				}
			})
			.catch(function (error) {
				dispatch({ type: FETCH_ERROR, payload: error.message });
				console.log("Error****:", error.message);
			});
	};
};

export const getUser = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.get("/user")
			.then((response) => {
				if (response.status === 200) {
					localStorage.setItem("user", JSON.stringify(response.data));
					dispatch({ type: FETCH_SUCCESS });
					dispatch({ type: USER_DATA, payload: response.data });
				} else {
					dispatch({ type: FETCH_ERROR, payload: response.data.error });
				}
			})
			.catch(function (error) {
				dispatch({ type: FETCH_ERROR, payload: error.message });
				console.log("Error****:", error.message);
			});
	};
};

export const userSignOut = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });

		axios
			.post("/logout")
			.then((response) => {
				const { data } = response;

				if (response.status === 204) {
					localStorage.removeItem("token");
					dispatch({ type: FETCH_SUCCESS });
					dispatch({ type: SIGNOUT_USER_SUCCESS });
				} else {
					dispatch({ type: FETCH_ERROR, payload: data.error });
				}
			})
			.catch(function (error) {
				dispatch({ type: FETCH_ERROR, payload: error.message });
				console.log("Error****:", error.message);
			});
	};
};
