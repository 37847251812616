import axios from "axios";

const route1 = "https://wszoom.arescoop.es"; // ROUTE LOCAL
const route2 = "https://wszoom.arescoop.es"; //ROUTE STAGE
const route3 = "https://wszoom.arescoop.es"; //ROUTE PRODUCTION

if (localStorage.getItem("token")) {
	axios.defaults.headers.common["Authorization"] =
		"Bearer " + localStorage.getItem("token");
}

const instance = axios.create({
	baseURL: `${route1}/api`,
	headers: {
		"Content-Type": "application/json",
	},
});

instance.interceptors.response.use(
	function (response) {
		// Do something with response data
		return response;
	},
	function (error) {
		// Do something with response error
		// if (error.status === 401) {
		//   // DELETE YOUR TOKEN
		//   this.removeToken();
		// }
		if (error.response && error.response.data.message === "Unauthenticated.") {
			localStorage.removeItem("token");
			window.location.href = "/signin";
		}

		return Promise.reject(error);
	}
);

export default instance;
