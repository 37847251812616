import axios from "../util/Api";

const AuthService = {
	ForgotPassword: (values) => {
		return axios.post("forgot-password", values);
	},
	ResetPassword: (values) => {
		return axios.post("reset-password", values);
	},
	ChangePassword: (values) => {
		return axios.post("/change-password", values);
	},
};

export default AuthService;
